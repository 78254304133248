import { Toaster } from "@egaranti/components";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.css";

import mixpanel from "mixpanel-browser";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/tr";
import moment from "moment/moment";
import { useRegisterSW } from "virtual:pwa-register/react";

import { getUserCountry } from "@/lib/api/countryDetector";
import ScrollToTop from "@/lib/utils/components/ScrollToTop";
import AppRoutes from "@/routes";

export default function App() {
  mixpanel.init("3b972b51542eea57c5af9ed20ad22692", {
    track_pageview: true,
    persistence: "localStorage",
  });
  const intervalMS = 1800000;

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      r &&
        setInterval(async () => {
          if (r.installing || !navigator) return;

          if ("connection" in navigator && !navigator.onLine) return;

          const resp = await fetch(swUrl, {
            cache: "no-store",
            headers: {
              cache: "no-store",
              "cache-control": "no-cache",
            },
          });

          if (resp?.status === 200) await r.update();
        }, intervalMS);
    },
    onRegistered(r) {
      // eslint-disable-next-line prefer-template
      console.log("SW Registered: " + r);
    },
    onRegisterError(error) {
      console.log("SW registration error", error);
    },
  });

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };

  useEffect(() => {
    getUserCountry();
  }, []);

  const { t } = useTranslation();

  return (
    <>
      <Toaster />
      <Router>
        <ScrollToTop />
        <AppRoutes />
      </Router>
      <div className="fixed bottom-4 right-4 z-50">
        {needRefresh && (
          <div className="flex items-center space-x-4 rounded-lg bg-white p-4 shadow-lg">
            <div className="flex-1">
              <span>
                {t(
                  "needReflesh.reload",
                  "Yeni içerik mevcut, güncellemek için yenile butonuna tıklayın.",
                )}
              </span>
            </div>
            {needRefresh && (
              <button
                className="rounded-lg bg-blue-600 px-4 py-2 text-white"
                onClick={() => updateServiceWorker(true)}
              >
                {t("common.reload", "Yenile")}
              </button>
            )}
            <button
              className="rounded-lg bg-gray-500 px-4 py-2 text-white"
              onClick={() => close()}
            >
              {t("common.close", "Kapat")}
            </button>
          </div>
        )}
      </div>
    </>
  );
}
