import React from "react";

import { Check, X } from "lucide-react";

const MessageActions = ({ status, sender, onAction }) => {
  if (status !== "WAITING_CUSTOMER" || sender !== "MERCHANT") {
    return null;
  }

  return (
    <div className="flex justify-end gap-2">
      <button
        onClick={() => onAction({ type: "status", status: "REJECTED" })}
        className="inline-flex items-center rounded-lg px-3 py-1 font-medium text-red-600 shadow-none hover:bg-red-50 hover:text-red-700"
      >
        <X className="mr-2 h-4 w-4" />
        Reddet
      </button>
      <button
        onClick={() => onAction({ type: "status", status: "APPROVED" })}
        className="inline-flex items-center rounded-lg px-3 py-1 font-medium text-green-600 shadow-none hover:bg-green-50 hover:text-green-700"
      >
        <Check className="mr-2 h-4 w-4" />
        Kabul Et
      </button>
    </div>
  );
};

export default MessageActions;
