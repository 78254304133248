import i18n from "@/i18n";

class StrapiService {
  constructor() {
    this.endpoint = "https://strapi.egaranti.co/api";
  }

  async getContentList(content, options = {}) {
    const { filterString, populate, sort } = options;

    let url = `${this.endpoint}/${content}`;
    const queryParams = new URLSearchParams();

    // Add populate parameter first
    queryParams.append("populate", populate || "*");

    // Add filter string directly to the URL
    if (filterString) {
      url += filterString.startsWith("?") ? filterString : `&${filterString}`;
    }

    // Add sort parameter
    if (sort) {
      queryParams.append("sort", sort);
    }

    // Add locale parameter
    if (i18n.language) {
      const supportedLocales = ["tr", "en", "ru"];
      const locale = supportedLocales.includes(i18n.language)
        ? i18n.language
        : "tr";
      queryParams.append("locale", locale);
    }

    // Add query parameters to URL
    url += url.includes("?")
      ? `&${queryParams.toString()}`
      : `?${queryParams.toString()}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { data } = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data from Strapi:", error);
      return [];
    }
  }
}

export default new StrapiService();
