import { Button } from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as AddressIcon } from "../../assets/svgs/accountpage/addressicon.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/svgs/accountpage/archiveicon.svg";
import { ReactComponent as GlobeIcon } from "../../assets/svgs/accountpage/globe.svg";
import { ReactComponent as ProfileIcon } from "../../assets/svgs/accountpage/profileicon.svg";
import AccountMenuCard from "../../components/Account/AccountMenuCard";
//import { ReactComponent as PaymentIcon } from "../../assets/svgs/accountpage/paymenticon.svg";
// import { ReactComponent as SecurityIcon } from "../../assets/svgs/accountpage/securityicon.svg";
// import { ReactComponent as NotificationIcon } from "../../assets/svgs/accountpage/notificationicon.svg";
// import { ReactComponent as SupportIcon } from "../../assets/svgs/accountpage/supporticon.svg";
import AccountMenuGrid from "../../components/Account/AccountMenuGrid";

import LogoutDialog from "@/components/Auth/LogoutDialog";

export default function Account() {
  const { t } = useTranslation();
  const [onOpenLogout, setOnOpenLogout] = React.useState(false);

  return (
    <div className="p-5 pb-[100px] md:pb-8">
      <AccountMenuGrid>
        <AccountMenuCard
          title={t("accountPage.card1Title")}
          icon={<ProfileIcon />}
          text={t("accountPage.card1Desc")}
          link="/account/profile"
        />
        <AccountMenuCard
          title={t("accountPage.card2Title")}
          icon={<AddressIcon />}
          text={t("accountPage.card2Desc")}
          link="/account/address"
        />
        <AccountMenuCard
          title={t("accountPage.card3Title")}
          icon={<GlobeIcon />}
          text={t("accountPage.card3Desc")}
          link="/account/language"
        />
        <AccountMenuCard
          title={t("accountPage.archivedProducts")}
          icon={<ArchiveIcon />}
          text={t("accountPage.archivedProductsDesc")}
          link="/account/archived-products"
        />
        {/* <AccountMenuCard
          title="Ödeme Bilgileri"
          icon={<PaymentIcon />}
          text="Ödeme yöntemlerinizi buradan yönetebilirsiniz."
          link="/account"
        /> */}
        {/* <AccountMenuCard
          title="Güvenlik"
          icon={<SecurityIcon />}
          text="Şifrenizi istediğiniz zaman değiştirebilir, hesabınızı daha güvenli kullanabilirsiniz."
          link="/account/security"
        /> */}
        {/* <AccountMenuCard
          title="Bildirimler"
          icon={<NotificationIcon />}
          text="Ticari Elektronik İletileri ve Bildirimlerinizi buradan yönetebilirsiniz."
          link="/account/notification"
        />
        <AccountMenuCard
          title="Destek"
          icon={<SupportIcon />}
          text="Herhangi bir sorunuz varsa veya daha fazla bilgi almak istiyorsanız lütfen bize ulaşın. "
          link="/account/support"
        /> */}
        {JSON.parse(localStorage.getItem("customizations"))?.sourceAppName !==
          "yapikredi" && (
          <div className="md:hidden">
            <Button className="w-full" onClick={() => setOnOpenLogout(true)}>
              <svg
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8333 13.1667L16 9M16 9L11.8333 4.83333M16 9H6M8.5 13.1667C8.5 13.9416 8.5 14.3291 8.41482 14.647C8.18365 15.5098 7.50978 16.1836 6.64705 16.4148C6.32913 16.5 5.94164 16.5 5.16667 16.5H4.75C3.58515 16.5 3.00272 16.5 2.54329 16.3097C1.93072 16.056 1.44404 15.5693 1.1903 14.9567C1 14.4973 1 13.9149 1 12.75V5.25C1 4.08515 1 3.50272 1.1903 3.04329C1.44404 2.43072 1.93072 1.94404 2.54329 1.6903C3.00272 1.5 3.58515 1.5 4.75 1.5H5.16667C5.94164 1.5 6.32913 1.5 6.64705 1.58519C7.50978 1.81635 8.18365 2.49022 8.41482 3.35295C8.5 3.67087 8.5 4.05836 8.5 4.83333"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {t("accountPage.logout")}
            </Button>
          </div>
        )}
      </AccountMenuGrid>
      <LogoutDialog open={onOpenLogout} onOpenChange={setOnOpenLogout} />
    </div>
  );
}
