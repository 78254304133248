import {
  Button,
  Input,
  Label,
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@egaranti/components";

import { useTranslation } from "react-i18next";

import useMediaQuery from "@/lib/utils/hooks/useMediaQuery";

const ReplySheet = ({
  open,
  onOpenChange,
  setReplyFile,
  replyContent,
  setReplyContent,
  handleSendReply,
}) => {
  const { t } = useTranslation();

  const isMobile = useMediaQuery("(max-width: 640px)");

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side={isMobile ? "bottom" : "right"}
        className="rounded-lg bg-white p-4 md:m-4"
      >
        <SheetHeader>
          <SheetTitle>{t("claimsPage.replyToAuthorizedService")}</SheetTitle>
        </SheetHeader>
        <div className="mt-4 flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <Label>{t("claimsPage.reply")}</Label>
            <Input
              onChange={(e) => setReplyContent(e.target.value)}
              value={replyContent}
            />
          </div>
          <Label>{t("claimsPage.toUploadDocument")}</Label>
          <Input
            type="file"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"
            onChange={(e) => {
              setReplyFile(e.target.files[0]);
            }}
          />
        </div>
        <SheetFooter className="mt-4 flex-col gap-2">
          <Button
            onClick={() => {
              onOpenChange();
            }}
            variant="secondaryGray"
          >
            {t("common.cancel")}
          </Button>
          <Button
            disabled={!replyContent}
            onClick={() => {
              handleSendReply();
            }}
          >
            {t("common.send")}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default ReplySheet;
