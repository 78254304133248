import { useEffect, useState } from "react";

import axios from "../../api/axios";

import mixpanel from "mixpanel-browser";

const useUser = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/individual-customers/info")
      .then((res) => {
        setUser(
          res.data || {
            name: "Guest",
            email: "guest@example.com",
            phone: "081234567890",
            address: "Jl. Imam Bonjol",
          },
        );

        if (res.data?.id) {
          try {
            // First identify with the user ID
            mixpanel.identify(res.data.id);

            // Only try to create alias if we have an anonymous ID
            const distinctId = mixpanel.get_distinct_id();
            if (distinctId && distinctId !== res.data.id) {
              try {
                mixpanel.alias(res.data.id, distinctId);
              } catch (aliasError) {
                // Ignore alias exists error
                console.log(
                  "Mixpanel alias error (can be ignored if already exists):",
                  aliasError,
                );
              }
            }
          } catch (error) {
            console.log("Mixpanel identify error:", error);
          }

          // Set basic Mixpanel properties with $ prefix
          const basicProps = {
            $email: res.data?.email,
            $name:
              `${res.data?.firstName || ""} ${res.data?.lastName || ""}`.trim(),
            $phone: res.data?.phone,
          };

          // Set custom properties without $ prefix
          const customProps = {
            gender: res.data?.gender,
            birth_date: res.data?.birthDate,
            email_verified: res.data?.emailVerified,
            phone_verified: res.data?.phoneVerified,
            created_at: res.data?.createdAt,
            updated_at: res.data?.updatedAt,
            membership_agreement: res.data?.membershipAgreement,
            egaranti_user_id: res.data.id,
          };

          // Filter out undefined/null values and set all properties
          const cleanBasicProps = Object.fromEntries(
            Object.entries(basicProps).filter(([_, v]) => v != null),
          );
          const cleanCustomProps = Object.fromEntries(
            Object.entries(customProps).filter(([_, v]) => v != null),
          );

          mixpanel.people.set({
            ...cleanBasicProps,
            ...cleanCustomProps,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { user, loading };
};

export default useUser;
