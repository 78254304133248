import React from "react";

import { MerchantIcon } from "./icons";

const MessageHeader = ({ sender, merchantName, customerName, createdAt }) => {
  return (
    <div className="flex cursor-pointer items-center justify-start p-4">
      <div className="ml-2 flex items-center gap-4">
        <h3 className="flex gap-2 text-sm font-semibold text-[#111729]">
          {sender === "MERCHANT" && <MerchantIcon />}
          {sender === "MERCHANT" ? merchantName : customerName}
        </h3>
        <span className="text-xs text-[#677389]">{createdAt}</span>
      </div>
    </div>
  );
};

export default MessageHeader;
