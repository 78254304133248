import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  useToast,
} from "@egaranti/components";
import { yupResolver } from "@hookform/resolvers/yup";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import Breadcrumb, {
  BreadcrumbItem,
} from "@/components/common/shared/breadcrumbs";

import { postAddress } from "@/lib/api/address";
import { parseError } from "@/lib/utils";
import { addressSchema } from "@/lib/utils/validation/schemas";

export default function NewAddress() {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const location = useLocation();
  const { toast } = useToast();

  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: yupResolver(addressSchema),
    defaultValues: {
      title: "",
      addressLine: "",
    },
  });

  const handleSave = (data) => {
    if (loading) return;

    setLoading(true);
    postAddress(data)
      .then((res) => {
        toast({
          description: t("Adres eklendi"),
          variant: "success",
        });
        navigateTo(
          location.state?.from ? location.state.from : "/account/address",
          {
            state: { product: location.state?.product },
          },
        );
      })
      .catch((err) => {
        const errorText = parseError(err.response.data.errors);
        toast({
          description: errorText,
          variant: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex w-full flex-col">
      <Breadcrumb>
        <BreadcrumbItem link="/account/address">
          {" "}
          {t("accountPage.myAddresses")}
        </BreadcrumbItem>
        <BreadcrumbItem active>{t("accountPage.newAddress")}</BreadcrumbItem>
      </Breadcrumb>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSave)}
          className="w-full bg-[#F9FAFC] p-4"
        >
          <div className="mx-auto mt-6 flex max-w-[1000px] flex-col gap-5 rounded-lg border border-[#EAECF0] bg-white p-[30px]">
            <h2 className="mb-2 font-medium">{t("accountPage.addressInfo")}</h2>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem className="w-full">
                  <Label>{t("accountPage.title")}</Label>
                  <FormControl>
                    <Input maxLength={40} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="addressLine"
              render={({ field }) => (
                <FormItem className="w-full">
                  <Label>{t("accountPage.address")}</Label>
                  <FormControl>
                    <Input maxLength={220} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end gap-4">
              <Button
                onClick={() => {
                  navigateTo("/account/address");
                }}
                variant="secondaryGray"
              >
                {t("accountPage.goBack")}
              </Button>
              <Button onSubmit={form.handleSubmit(handleSave)}>
                {t("common.save")}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}
