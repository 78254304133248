import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  useToast,
} from "@egaranti/components";
import { yupResolver } from "@hookform/resolvers/yup";

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import axios from "../../../lib/api/axios";
import DocumentItem from "../DocumentItem";
import ProductIcon from "../ProductIcon";
import DeleteDialog from "./deleteDialog";

import PriceInputPrice from "@/components/common/PriceInputBox";

import moment from "moment";
import { useHookFormMask } from "use-mask-input";
import * as yup from "yup";

import useMediaQuery from "@/lib/utils/hooks/useMediaQuery";

const UnVerifiedProductDetailSheet = ({
  open,
  onOpenChange,
  selectedProduct,
  fetchProducts,
}) => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [sheetState, setSheetState] = useState("overview");
  const [product, setProduct] = useState(null);

  useState([]);
  const [loading, setLoading] = useState(true);
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required(
        t("addNewProductSchema.productNameRequired", "Ürün adı zorunludur"),
      ),
    priceUnit: yup
      .string()
      .required(
        t("addNewProductSchema.priceUnitRequired", "Para birimi zorunludur"),
      ),
    productPrice: yup
      .number()
      .nullable()
      .transform((value) => (isNaN(value) ? null : value))
      .required(t("addNewProductSchema.priceRequired", "Fiyat zorunludur"))
      .typeError(t("addNewProductSchema.priceRequired", "Fiyat zorunludur")),
    warrantyYear: yup
      .number()
      .required(
        t(
          "addNewProductSchema.warrantyYearRequired",
          "Garanti süresi zorunludur",
        ),
      ),
    warrantyStartDate: yup
      .string()
      .required(
        t(
          "addNewProductSchema.purchaseDateRequired",
          "Satın alma tarihi zorunludur",
        ),
      )
      .matches(
        /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[0-2])[/]\d{4}$/,
        t(
          "addNewProductSchema.invalidDate",
          "Geçerli bir tarih giriniz (GG/AA/YYYY)",
        ),
      ),
  });
  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: "",
      priceUnit: "TRY",
      productPrice: 0,
      warrantyYear: "",
      warrantyStartDate: "",
      unverifiedProductCategoryId: "",
    },
  });

  const handleDeleteProduct = async () => {
    await axios
      .delete(`/unverified-products/${selectedProduct.id}`)
      .then((res) => {
        fetchProducts();
        onOpenChange();
      });
  };

  const getUnverifiedProduct = async () => {
    setLoading(true);
    try {
      const res = await axios.get(
        `/unverified-products/${selectedProduct?.id}`,
      );
      setProduct(res.data);
      form.setValue("name", res.data.name);
      form.setValue("brandName", res.data.brandName);
      form.setValue("priceUnit", res.data.priceUnit || "TRY");
      form.setValue("productPrice", res.data.productPrice);
      form.setValue("warrantyYear", res.data.warrantyYear);
      form.setValue(
        "warrantyStartDate",
        moment(res.data.warrantyStartDate, "YYYY-MM-DD").format("DD/MM/YYYY"),
      );
      form.setValue("unverifiedProductCategoryId", res.data.categoryId);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open && selectedProduct?.id) {
      getUnverifiedProduct();
    }
    return () => {
      setProduct(null);
    };
  }, [open, selectedProduct]);

  const isMobile = useMediaQuery("(max-width: 768px)");

  const onSubmit = async (data) => {
    const bodyObj = {
      ...data,
      warrantyStartDate: moment(data.warrantyStartDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD",
      ),
    };
    setLoading(true);
    try {
      await axios.put(`/unverified-products/${selectedProduct.id}`, bodyObj);
      onOpenChange();
      fetchProducts();
    } catch (err) {
      toast({
        variant: "error",
        title: err.response.data?.errors[0]?.message,
      });
    } finally {
      setLoading(false);
    }
  };
  const registerWithMask = useHookFormMask(form.register);

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent
        side={isMobile ? "bottom" : "right"}
        className="rounded-lg bg-white p-0 md:m-4 "
      >
        {sheetState === "overview" && (
          <>
            <SheetHeader className="p-4">
              <SheetTitle>{t("productsPage.productDetail")}</SheetTitle>
            </SheetHeader>
            <div className="flex w-full flex-col pb-4">
              <div className="flex flex-col items-center justify-center border-b pb-8 pt-2">
                <ProductIcon
                  className="text-[#4A5567]"
                  productType={product?.category}
                />
                <div className="mt-3 flex items-center justify-between gap-2">
                  <h2 className="text-center text-xl font-semibold">
                    {product?.brandName}
                  </h2>
                  <hr className="h-4 w-px bg-gray-200" />
                  <h2 className="text-center text-base font-medium text-[#677389]">
                    {product?.name}
                  </h2>
                </div>
              </div>
              <div className="flex flex-col items-center gap-4 border-b py-6">
                <h2 className="text-sm font-medium text-[#111729]">
                  {t("productsPage.actions")}
                </h2>
                <div className="flex items-center justify-center gap-6">
                  <button
                    onClick={() => setSheetState("edit")}
                    aria-label="Düzenle"
                    className="flex h-10 w-10 items-center justify-center gap-2 rounded-lg bg-[#F5F7FA] p-2 transition-all hover:bg-slate-200"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_4128_97237)">
                        <path
                          d="M9.16699 3.3332H5.66699C4.26686 3.3332 3.5668 3.3332 3.03202 3.60568C2.56161 3.84536 2.17916 4.22782 1.93948 4.69822C1.66699 5.233 1.66699 5.93307 1.66699 7.3332V14.3332C1.66699 15.7333 1.66699 16.4334 1.93948 16.9682C2.17916 17.4386 2.56161 17.821 3.03202 18.0607C3.5668 18.3332 4.26686 18.3332 5.66699 18.3332H12.667C14.0671 18.3332 14.7672 18.3332 15.302 18.0607C15.7724 17.821 16.1548 17.4386 16.3945 16.9682C16.667 16.4334 16.667 15.7333 16.667 14.3332V10.8332M6.66697 13.3332H8.06242C8.47007 13.3332 8.6739 13.3332 8.86571 13.2871C9.03577 13.2463 9.19835 13.179 9.34747 13.0876C9.51566 12.9845 9.65979 12.8404 9.94804 12.5521L17.917 4.5832C18.6073 3.89284 18.6073 2.77355 17.917 2.0832C17.2266 1.39284 16.1073 1.39284 15.417 2.0832L7.44802 10.0521C7.15977 10.3404 7.01564 10.4845 6.91257 10.6527C6.82119 10.8018 6.75385 10.9644 6.71302 11.1345C6.66697 11.3263 6.66697 11.5301 6.66697 11.9378V13.3332Z"
                          stroke="#364153"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4128_97237">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  <DeleteDialog
                    triggerValue={
                      <button
                        aria-label="Düzenle"
                        className="flex h-10 w-10 items-center justify-center gap-2 rounded-lg bg-[#F5F7FA] p-2 transition-all hover:bg-slate-200"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6"
                            stroke="#D92D20"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    }
                    on={() => {
                      handleDeleteProduct();
                    }}
                    desc={t("productsPage.youAreAboutToDeleteProduct")}
                  />
                </div>
              </div>
              <div className="mt-8 flex w-full flex-col items-center justify-center border-b px-6 pb-8">
                <div className="flex w-full justify-between">
                  <h3 className="text-base font-medium uppercase text-[#677389]">
                    {t("productsPage.warrantyPeriod")}
                  </h3>
                </div>
                <div className="mb-8 mt-4 flex w-full flex-col rounded-lg border border-[#E3E7EF]">
                  <div className="flex items-center justify-between rounded-lg rounded-t-none border border-[#F9FAFC] bg-white px-4 py-[26px]">
                    <h4 className="text-sm text-[#677389] md:text-[16px]">
                      {t("productsPage.warrantyEndDate")}
                    </h4>
                    <span className="text-right text-sm font-semibold text-[#111729] md:text-[18px]">
                      {moment(product?.warrantyEndDate, "DD-MM-YYYY").format(
                        "DD MMMM YYYY",
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-8 flex w-full flex-col items-center justify-center px-6">
                <div className="flex w-full justify-between">
                  <h3 className="text-base font-medium uppercase text-[#677389]">
                    {t("productsPage.documents")}
                  </h3>
                </div>
                <div className="mt-4 flex w-full flex-col gap-3 pb-8">
                  <DocumentItem
                    getUnverifiedProduct={getUnverifiedProduct}
                    document={{
                      url: product?.billingUrl,
                      name: t("productsPage.billingDocument"),
                      type: "billing",
                    }}
                    product={product}
                  />
                  <DocumentItem
                    getUnverifiedProduct={getUnverifiedProduct}
                    document={{
                      url: product?.warrantyUrl,
                      name: t("productsPage.warrantyDocument"),
                      type: "warranty",
                    }}
                    product={product}
                  />
                  <DocumentItem
                    getUnverifiedProduct={getUnverifiedProduct}
                    document={{
                      url: product?.serviceUrl,
                      name: t("productsPage.serviceDocument"),
                      type: "service",
                    }}
                    product={product}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {sheetState === "edit" &&
          (loading ? (
            <div className="flex h-full w-full flex-col items-center justify-center gap-4">
              <svg
                aria-hidden="true"
                className="animate-spin mr-2 h-16 w-16 fill-[#0049e6] text-gray-200 dark:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          ) : (
            <>
              <SheetHeader className="p-4">
                <SheetTitle>{t("productsPage.productDetailEdit")}</SheetTitle>
              </SheetHeader>
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="flex flex-col gap-4 p-4"
                >
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <Label>{t("productsPage.productName")}</Label>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="productPrice"
                    render={({ field }) => (
                      <FormItem>
                        <Label>{t("productsPage.currency")}</Label>
                        <FormControl>
                          <PriceInputPrice
                            valueUnit={form.getValues().priceUnit}
                            value={field.value}
                            onChange={field.onChange}
                            onChangeUnit={(value) =>
                              form.setValue("priceUnit", value)
                            }
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="warrantyYear"
                    render={({ field }) => (
                      <FormItem>
                        <Label>{t("productsPage.warrantyPeriod")}</Label>
                        <FormControl>
                          <Input type="number" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="warrantyStartDate"
                    render={({ field }) => (
                      <FormItem>
                        <Label>{t("productsPage.purchaseDate")}</Label>
                        <FormControl>
                          <Input
                            type="tel"
                            {...field}
                            {...registerWithMask("warrantyStartDate", {
                              mask: "99/99/9999",
                            })}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="flex justify-end gap-4">
                    <Button
                      type="button"
                      variant="secondaryGray"
                      onClick={() => setSheetState("overview")}
                    >
                      {t("common.back")}
                    </Button>
                    <Button type="submit" disabled={loading}>
                      {t("common.save")}
                    </Button>
                  </div>
                </form>
              </Form>
            </>
          ))}
      </SheetContent>
    </Sheet>
  );
};

export default UnVerifiedProductDetailSheet;
