import React from "react";
import { Route } from "react-router-dom";

import { Account } from "../pages";
import AddressPage from "../pages/Account/Address";
import AddresDetail from "../pages/Account/Address/AddressDetail";
import EditAddress from "../pages/Account/Address/EditAddress";
import AddAddress from "../pages/Account/Address/NewAddress";
import ArchivedProducts from "../pages/Account/ArchivedProducts";
import Profile from "../pages/Account/Profile";
import PrivateRoute from "./PrivateRoute";

import LanguagePage from "@/pages/Account/Language";

export const accountRoutes = (
  <>
    <Route
      path="/account"
      element={
        <PrivateRoute>
          <Account />
        </PrivateRoute>
      }
    />
    <Route path="/account/profile" element={<Profile />} />
    <Route
      path="/account/language"
      element={
        <PrivateRoute>
          <LanguagePage />
        </PrivateRoute>
      }
    />
    <Route path="/account/address" element={<AddressPage />} />

    <Route path="/account/address/:id" element={<AddresDetail />} />
    <Route path="/account/address/edit/:id" element={<EditAddress />} />
    <Route path="/account/address/add" element={<AddAddress />} />
    <Route path="/account/archived-products" element={<ArchivedProducts />} />
  </>
);
