import React from "react";

import { DownloadIcon, FileIcon } from "./icons";

const FileAttachment = ({ path }) => {
  const openFile = (path) => window.open(path, "_blank");

  return (
    <div className="my-4 w-full max-w-[400px] rounded-lg border border-[#E3E7EF] bg-white p-4">
      <div
        onClick={() => openFile(path)}
        aria-label="Dosya indir"
        role="button"
        className="flex items-center justify-between gap-2"
      >
        <div className="flex items-center gap-3">
          <FileIcon />
          <div className="flex flex-col gap-1">
            <p className="text-[#111729]">Belge</p>
            <span className="text-sm text-[#677389]" />
          </div>
        </div>
        <button aria-label="Dosyayı indir">
          <DownloadIcon />
        </button>
      </div>
    </div>
  );
};

export default FileAttachment;
