import {
  Button,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "@egaranti/components";

import { useState } from "react";

import { SelectableCard } from "../SelectableCard";

import { cn } from "@/lib/utils";

const BrandStep = ({
  brands,
  showMore,
  setShowMore,
  selectedBrand,
  selectedCustomBrand,
  onSelectBrand,
  onToggleShowMore,
  control,
  setValue,
  trigger,
  t,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="font-semibold">{t("productForm.brands", "Markalar")}</h2>
      {brands.length > 0 && (
        <div className="mb-5 grid grid-cols-2 gap-4 sm:grid-cols-3">
          {brands.slice(0, showMore ? brands.length : 6).map((brand) => (
            <SelectableCard
              key={brand.id}
              item={brand}
              type="brand"
              selected={selectedBrand === brand.id}
              onClick={() => onSelectBrand(brand.id)}
            />
          ))}
        </div>
      )}
      {brands.length > 6 && (
        <button className="text-blue-500" onClick={onToggleShowMore}>
          {showMore
            ? t("productForm.showLess", "Daha az göster")
            : t("productForm.showMore", "Daha fazla göster")}
        </button>
      )}
      <FormField
        control={control}
        name="customBrand"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t("productsPage.newBrandName", "Yeni Marka Adı")}
            </FormLabel>
            <FormControl>
              <Input
                maxLength={50}
                onFocus={() => setValue("brand", "")}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};

export default BrandStep;
