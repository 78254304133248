import { Button } from "@egaranti/components";
import { useToast } from "@egaranti/components";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import axios from "../../lib/api/axios";

import EmptyData from "@/components/common/EmptyData";
import Breadcrumb, {
  BreadcrumbItem,
} from "@/components/common/shared/breadcrumbs";

export default function ArchivedProducts() {
  const { t } = useTranslation();
  const { toast } = useToast();
  const [archivedProducts, setArchivedProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchArchivedProducts();
  }, []);

  const fetchArchivedProducts = async () => {
    try {
      const response = await axios.get("/individual-customer-products/archive");
      setArchivedProducts(response.data.content);
    } catch (error) {
      console.error("Error fetching archived products:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUnarchive = async (productId) => {
    try {
      await axios.post(`/individual-customer-products/unarchive`, {
        individualCustomerProductId: productId,
      });
      toast({
        title: t("product.unarchiveSuccess", "Ürün arşivden kaldırıldı"),
        description: t(
          "product.unarchiveSuccessDescription",
          "Ürünlerim sayfasından erişebilirsin",
        ),
        variant: "success",
      });
      fetchArchivedProducts();
    } catch (error) {
      console.error("Error unarchiving product:", error);
    }
  };

  if (loading) {
    return <div className="p-5">Loading...</div>;
  }

  return (
    <div className="flex w-full items-center justify-between">
      <main className="w-full flex-col bg-[#F9FAFC]">
        <Breadcrumb>
          <BreadcrumbItem link="/account">
            {t("accountPage.myAccount")}
          </BreadcrumbItem>
          <BreadcrumbItem active>
            {t("accountPage.archivedProducts")}
          </BreadcrumbItem>
        </Breadcrumb>
        <div className="h-full p-7">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            {archivedProducts.map((product) => (
              <div
                key={product.id}
                className="flex min-h-[200px] flex-col justify-between rounded-lg border border-gray-200 bg-white p-4 shadow-sm"
              >
                <div className="mb-4 flex flex-col gap-2">
                  <p className="truncate text-sm text-gray-600">
                    {t(product?.category?.split(">").pop().trim())}
                  </p>
                  <h3 className="break-words text-lg font-medium leading-tight">
                    {product.name}
                  </h3>
                  <p className="truncate text-sm text-gray-600">
                    {product.brand}
                  </p>
                </div>
                <Button
                  variant="secondaryColor"
                  onClick={() => handleUnarchive(product.id)}
                  className="w-full"
                  size="sm"
                >
                  {t("product.unarchive", "Arşivden Kaldır")}
                </Button>
              </div>
            ))}
            {archivedProducts.length === 0 && (
              <EmptyData
                className="col-span-full"
                title={t(
                  "product.noArchivedProducts",
                  "Arşivlenmiş ürün bulunmuyor",
                )}
              />
            )}
          </div>
        </div>
      </main>
    </div>
  );
}
