import { create } from "zustand";

import axios from "@/lib/api/axios";

const useClaimsStore = create((set, get) => ({
  // Service Claims State
  serviceClaims: [],
  serviceLoading: false,
  serviceFilters: {
    individualCustomerProductName: "",
    serviceClaimStatus: "",
  },
  servicePagination: {
    page: 1,
    size: 10,
    totalPages: 1,
    totalElements: 0,
  },

  // Setup Claims State
  setupClaims: [],
  setupLoading: false,
  setupFilters: {
    individualCustomerProductName: "",
    setupClaimStatus: "",
  },
  setupPagination: {
    page: 1,
    size: 10,
    totalPages: 1,
    totalElements: 0,
  },

  // Actions
  setServiceFilters: (filters) => set({ serviceFilters: filters }),
  setSetupFilters: (filters) => set({ setupFilters: filters }),

  clearServiceFilters: () => {
    set({
      serviceFilters: {
        individualCustomerProductName: "",
        serviceClaimStatus: "",
      },
    });
    get().fetchServiceClaims();
  },

  clearSetupFilters: () => {
    set({
      setupFilters: {
        individualCustomerProductName: "",
        setupClaimStatus: "",
      },
    });
    get().fetchSetupClaims();
  },

  fetchServiceClaims: async (
    filters = get().serviceFilters,
    pagination = get().servicePagination,
  ) => {
    set({ serviceLoading: true });
    try {
      const params = { ...filters, ...pagination };
      const res = await axios.get(
        "/individual-customer-product-service-claims",
        { params },
      );

      set({
        serviceClaims: res.data.content,
        servicePagination: {
          page: res.data.page,
          size: 10,
          totalPages: res.data.totalPages,
          totalElements: res.data.totalElements,
        },
        serviceLoading: false,
      });
    } catch (error) {
      console.error(error);
      set({ serviceLoading: false });
    }
  },

  fetchSetupClaims: async (
    filters = get().setupFilters,
    pagination = get().setupPagination,
  ) => {
    set({ setupLoading: true });
    try {
      const params = { ...filters, ...pagination };
      const res = await axios.get("/individual-customer-product-setup-claims", {
        params,
      });

      set({
        setupClaims: res.data.content,
        setupPagination: {
          page: res.data.page,
          size: 10,
          totalPages: res.data.totalPages,
          totalElements: res.data.totalElements,
        },
        setupLoading: false,
      });
    } catch (error) {
      console.error(error);
      set({ setupLoading: false });
    }
  },
}));

export default useClaimsStore;
