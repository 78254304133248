import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Button,
} from "@egaranti/components";

import React from "react";
import { useTranslation } from "react-i18next";

import productStore from "@/store/useProductStore";

import { ReactComponent as ArchiveIcon } from "@/assets/svgs/archiveicon.svg";

import axios from "@/lib/api/axios";

const ActionButtons = ({ toastInform, t, product, onOpenChange }) => {
  const { fetchProducts } = productStore();
  const handleArchive = async () => {
    try {
      await axios.post(`/individual-customer-products/archive`, {
        individualCustomerProductId: product.id,
      });
      fetchProducts();
      onOpenChange();
    } catch (error) {
      console.error("Error archiving product:", error);
    }
  };

  return (
    <div className="flex flex-col items-center gap-4 border-b py-6">
      <h2 className="text-sm font-medium text-[#111729]">
        {t("productsPage.actions")}
      </h2>
      <div className="flex items-center justify-center gap-6">
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <button className="group flex flex-col items-center gap-1">
              <ArchiveIcon className="flex h-9 w-9 items-center justify-center gap-2 rounded-lg bg-[#F5F7FA] p-2 transition-all group-hover:bg-slate-200" />
              <span
                className="cursor-pointer text-xs font-medium text-[#111729]"
                onClick={(e) => e.stopPropagation()}
              >
                {t("common.archive")}
              </span>
            </button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                {t("productsPage.archiveConfirmation")}
              </AlertDialogTitle>
            </AlertDialogHeader>
            <AlertDialogFooter className="flex flex-col gap-2">
              <AlertDialogCancel>{t("common.cancel")}</AlertDialogCancel>
              <AlertDialogAction onClick={handleArchive}>
                {t("common.archive")}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </div>
  );
};

export default ActionButtons;
