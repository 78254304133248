import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Label,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  useToast,
} from "@egaranti/components";
import { yupResolver } from "@hookform/resolvers/yup";

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Breadcrumb, {
  BreadcrumbItem,
} from "@/components/common/shared/breadcrumbs";

import { getAddress, putAddress } from "@/lib/api/address";
import axios from "@/lib/api/axios";
import { parseError } from "@/lib/utils";
import { addressEditSchema } from "@/lib/utils/validation/schemas";

export default function EditAddress() {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const { toast } = useToast();
  const addressId = window.location.pathname.split("/")[4];

  const [title, setTitle] = useState("");

  const form = useForm({
    resolver: yupResolver(addressEditSchema),
    defaultValues: {
      title: "",
      addressLine: "",
    },
    shouldUnregister: false,
  });

  useEffect(() => {
    getAddress(addressId).then((res) => {
      form.reset(res.data);
      setTitle(res.data.title);
    });
  }, []);

  const handleSave = (data) => {
    const request = {
      ...data,
    };

    putAddress(addressId, request)
      .then((res) => {
        navigateTo("/account/address", { replace: true });
      })
      .catch((err) => {
        const errorText = parseError(err.response.data.errors);

        toast({
          description: errorText,
          variant: "error",
        });
      });
  };

  return (
    <div className="flex w-full flex-col">
      <Breadcrumb>
        <BreadcrumbItem link="/account/address">
          {" "}
          {t("accountPage.myAddresses")}
        </BreadcrumbItem>
        <BreadcrumbItem active>
          {t("accountPage.editAddress")} - {title}
        </BreadcrumbItem>
      </Breadcrumb>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleSave)}
          className="w-full bg-[#F9FAFC] p-4"
        >
          <div className="mx-auto mt-6 flex max-w-[1000px] flex-col gap-5 rounded-lg border border-[#EAECF0] bg-white p-[30px]">
            <h2 className="mb-2 font-medium">{t("accountPage.addressInfo")}</h2>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem className="w-full">
                  <Label>{t("accountPage.title")}</Label>
                  <FormControl>
                    <Input maxLength={40} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="addressLine"
              render={({ field }) => (
                <FormItem className="w-full">
                  <Label>{t("accountPage.address")}</Label>
                  <FormControl>
                    <Input maxLength={220} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end gap-4">
              <Button
                onClick={() => {
                  navigateTo("/account/address");
                }}
                variant="secondaryGray"
              >
                {t("accountPage.goBack")}
              </Button>
              <Button onSubmit={form.handleSubmit(handleSave)}>
                {t("common.save")}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}
