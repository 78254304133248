import { useToast } from "@egaranti/components";

import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import DummyFileIcon from "../../assets/images/dummyfile.png";
import axios from "../../lib/api/axios";
import DeleteDialog from "./UnVerifiedProductDetailSheet/deleteDialog";

import { parseError } from "@/lib/utils";

const DocumentItem = ({
  document: { name = "Fatura Belgesi", url, type },
  product,
  getUnverifiedProduct,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState(null);
  const { toast } = useToast();
  const inputRef = useRef(null);

  const onFileChange = async (e, name) => {
    e.preventDefault();

    const file = e.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      handleUploadDocument(formData, type);
    }
  };

  const handleUploadDocument = async (formData, type) => {
    // /unverified-products/{unverifiedProductId}/warranty

    try {
      const res = await axios.post(
        `/unverified-products/${product.id}/${type}`,
        formData,
      );
      getUnverifiedProduct();
    } catch (err) {
      const errorText = parseError(err.response.data?.errors);
      toast({
        description: errorText,
        variant: "error",
      });
    }
  };

  const onDeleteFile = (type) => {
    setSelectedFile({ fileName: type });

    DeleteFileModalHook.handleToggle();
  };

  const handleDeleteDocument = async () => {
    try {
      const res = await axios.delete(
        `/unverified-products/${product.id}/${selectedFile.fileName}`,
      );
      getUnverifiedProduct();
    } catch (err) {
      const errorText = parseError(err.response.data?.errors);
      toast({
        description: errorText,
        variant: "error",
      });
    }
  };

  if (url) {
    return (
      <div className="flex w-full items-center justify-between rounded-lg border border-[#E3E7EF] bg-[#F2F5F9] p-4">
        <div className="flex items-center gap-2">
          <img src={DummyFileIcon} alt="dummy file icon" className="h-8 w-8" />
          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-medium text-[#111729]">{name}</h3>
            {/* <span className="text-[#677389] text-xs font-medium ">200KB</span> */}
          </div>
        </div>
        <div className="flex items-center">
          <button
            onClick={() => navigate(`/file-preview?url=${url}`)}
            aria-label="İndir"
            className="flex shrink-0 items-center justify-center p-2"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.6667 8.33333L17.5 2.5M17.5 2.5H12.5M17.5 2.5V7.5M8.33333 11.6667L2.5 17.5M2.5 17.5H7.5M2.5 17.5L2.5 12.5"
                stroke="#111729"
                strokeWidth="1.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <DeleteDialog
            triggerValue={
              <button
                onClick={() => onDeleteFile(type)}
                aria-label="Sil"
                className="flex shrink-0 items-center justify-center p-2"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 3H15M3 6H21M19 6L18.2987 16.5193C18.1935 18.0975 18.1409 18.8867 17.8 19.485C17.4999 20.0118 17.0472 20.4353 16.5017 20.6997C15.882 21 15.0911 21 13.5093 21H10.4907C8.90891 21 8.11803 21 7.49834 20.6997C6.95276 20.4353 6.50009 20.0118 6.19998 19.485C5.85911 18.8867 5.8065 18.0975 5.70129 16.5193L5 6"
                    stroke="#D92D20"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            }
            desc={t("productsPage.youAreAboutToDeleteDocument")}
            on={handleDeleteDocument}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div
        onClick={(e) => {
          inputRef.current.click();
        }}
        className="flex w-full cursor-pointer items-center justify-between rounded-lg border border-dashed border-[#E3E7EF] bg-white p-4"
      >
        <input
          onChange={(e) => onFileChange(e, name)}
          ref={inputRef}
          type="file"
          accept=".pdf,.jpg,.jpeg,.png"
          className="hidden"
        />
        <div className="flex items-center gap-2">
          <svg
            width="37"
            height="36"
            viewBox="0 0 37 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="2.5" y="2" width="32" height="32" rx="16" fill="#AAC2F7" />
            <path
              d="M19.8337 11.5131V14.2668C19.8337 14.6402 19.8337 14.8269 19.9063 14.9695C19.9702 15.0949 20.0722 15.1969 20.1977 15.2608C20.3403 15.3335 20.527 15.3335 20.9003 15.3335H23.654M23.8337 16.659V21.4668C23.8337 22.5869 23.8337 23.147 23.6157 23.5748C23.4239 23.9511 23.118 24.2571 22.7416 24.4488C22.3138 24.6668 21.7538 24.6668 20.6337 24.6668H16.367C15.2469 24.6668 14.6868 24.6668 14.259 24.4488C13.8827 24.2571 13.5767 23.9511 13.385 23.5748C13.167 23.147 13.167 22.5869 13.167 21.4668V14.5335C13.167 13.4134 13.167 12.8533 13.385 12.4255C13.5767 12.0492 13.8827 11.7432 14.259 11.5515C14.6868 11.3335 15.2469 11.3335 16.367 11.3335H18.5082C18.9974 11.3335 19.2419 11.3335 19.4721 11.3888C19.6762 11.4377 19.8713 11.5186 20.0502 11.6282C20.2521 11.7519 20.425 11.9249 20.7709 12.2708L22.8964 14.3962C23.2423 14.7421 23.4153 14.9151 23.5389 15.1169C23.6486 15.2959 23.7294 15.491 23.7784 15.695C23.8337 15.9252 23.8337 16.1698 23.8337 16.659Z"
              stroke="#0049E6"
              strokeWidth="1.33333"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect
              x="2.5"
              y="2"
              width="32"
              height="32"
              rx="16"
              stroke="#CCDBFA"
              strokeWidth="4"
            />
          </svg>
          <div className="flex flex-col gap-2">
            <h3 className="text-sm font-medium text-[#667085]">
              {name} {t("productsPage.forUploadClickHere")}{" "}
              <span className="cursor-pointer text-[#0049E6]">
                {t("productsPage.clickHere")}
              </span>
            </h3>
            <span className="text-xs font-medium text-[#677389] ">
              {t("productsPage.maxFileSize")}
            </span>
          </div>
        </div>
      </div>
    );
  }
};

export default DocumentItem;
