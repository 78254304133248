import { useTranslation } from "react-i18next";

import PlaceHolderIcon from "@/assets/images/placeholder-icon.png";

import { cn } from "@/lib/utils";

export const SelectableCard = ({ selected, onClick, item, type }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center">
      <div
        className={cn(
          "flex h-full w-full cursor-pointer flex-col items-center justify-center rounded-lg border bg-white p-4",
          selected
            ? "border-2 border-[#0049E6]"
            : "border-gray-200 hover:border-blue-200",
        )}
        onClick={onClick}
      >
        <img
          className="max-w-12"
          src={item.logoUrl || PlaceHolderIcon}
          alt={item.name}
        />
      </div>
      <span
        className={cn(
          "mt-2 text-center text-xs font-semibold",
          selected ? "text-[#0049E6]" : "text-gray-500",
        )}
      >
        {type === "category"
          ? t(item.name)
          : item.name.length > 20
            ? item.name.substring(0, 20) + "..."
            : item.name}
      </span>
    </div>
  );
};
