import React from "react";

import FileAttachment from "./FileAttachment";
import MessageActions from "./MessageActions";
import MessageHeader from "./MessageHeader";

import { motion } from "framer-motion";

const MessageBox = ({ message, status, handleMessageAction }) => {
  return (
    <div className="flex flex-col rounded-lg bg-[#F9FAFC] py-4">
      <MessageHeader
        sender={message.sender}
        merchantName={message.merchantName}
        customerName={message.individualCustomerFullName}
        createdAt={message.createdAt}
      />

      <motion.div className="overflow-hidden">
        <div className="flex flex-col gap-5 rounded-lg px-8">
          {message.message && (
            <p className="mt-4 break-words">{message.message}</p>
          )}

          {message?.path && <FileAttachment path={message.path} />}

          <MessageActions
            status={status}
            sender={message.sender}
            onAction={handleMessageAction}
          />
        </div>
      </motion.div>
    </div>
  );
};

export default MessageBox;
