import { create } from "zustand";

import axios from "@/lib/api/axios";

const useStore = create((set, get) => ({
  filters: {
    name: "",
    mainCategory: "",
    category: "",
    subCategory: "",
    subCategoryBrand: "",
    brand: "",
  },
  setFilters: (filters) => set({ filters }),
  openFilterSheet: false,
  setOpenFilterSheet: (open) => set({ openFilterSheet: open }),
  openAddProductSheet: false,
  setOpenAddProductSheet: (open) => set({ openAddProductSheet: open }),
  selectedProduct: {},
  setSelectedProduct: (product) => set({ selectedProduct: product }),
  openDetailSheet: false,
  setOpenDetailSheet: (open) => set({ openDetailSheet: open }),

  stateSelectedFilters: {
    name: "",
    mainCategory: { name: "", id: "" },
    category: { name: "", id: "" },
    subCategory: { name: "", id: "" },
    brand: { name: "", id: "" },
  },
  setStateSelectedFilters: (filters) => set({ stateSelectedFilters: filters }),

  verifiedProducts: [],
  unVerifiedProducts: [],
  loading: false,

  selectedUnverifiedProduct: null,
  openUnverifiedDetailSheet: false,
  setSelectedUnverifiedProduct: (product) =>
    set({ selectedUnverifiedProduct: product }),
  setOpenUnverifiedDetailSheet: (open) =>
    set({ openUnverifiedDetailSheet: open }),

  selectedVerifiedProduct: null,
  openVerifiedDetailSheet: false,
  setSelectedVerifiedProduct: (product) =>
    set({ selectedVerifiedProduct: product }),
  setOpenVerifiedDetailSheet: (open) => set({ openVerifiedDetailSheet: open }),

  fetchProducts: async (filters = {}) => {
    set({ loading: true });

    try {
      await new Promise((resolve) => setTimeout(resolve, 500)); // Keep the fake wait
      const [verifiedRes, unverifiedRes] = await Promise.all([
        axios.get("/individual-customer-products/list", { params: filters }),
        axios.get("/unverified-products", { params: filters }),
      ]);

      set({
        verifiedProducts: verifiedRes.data || [],
        // Make sure we're accessing the correct property and provide a default empty array
        unVerifiedProducts: unverifiedRes.data?.content || [],
        loading: false,
      });
    } catch (error) {
      console.error("Error fetching products", error);
      set({ loading: false });
    }
  },

  clearFilters: () => {
    set({
      filters: {
        name: "",
        mainCategory: "",
        category: "",
        subCategory: "",
        subCategoryBrand: "",
        brand: "",
      },
    });
    get().fetchProducts({});
  },
}));

export default useStore;
