import { useTranslation } from "react-i18next";

import { SelectableCard } from "../SelectableCard";

const CategoryStep = ({ categories, selectedCategory, onSelect }) => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="my-4 font-semibold text-[#111729]">
        {t("productForm.categories", "Kategoriler")}
      </h2>
      <div className="mb-6 grid grid-cols-3 gap-4">
        {categories?.map((category) => (
          <SelectableCard
            item={category}
            key={category.id}
            type="category"
            selected={selectedCategory === category.id}
            onClick={() => onSelect(category.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default CategoryStep;
