import { initReactI18next } from "react-i18next";

import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
import { locizePlugin } from "locize";

//import LastUsed from "locize-lastused";

const detectionOptions = {
  order: ["querystring", "localStorage", "navigator"],
  lookupQuerystring: "lng",
  lookupLocalStorage: "i18nextLng",
  caches: ["localStorage"],
  convertDetectedLanguage: (lng) =>
    lng.indexOf("-") > -1 ? lng.toLowerCase().split("-")[0] : lng,
};

const locizeOptions = {
  projectId: "a90d7a58-75b9-4284-870d-3b0b48302bdf",
  apiKey: import.meta.env.VITE_LOCIZE_API_KEY, // YOU should not expose your apps API key to production!!!
  referenceLng: "tr",
};

i18n
  .use(locizePlugin)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "tr",
    detection: detectionOptions,
    interpolation: {
      escapeValue: false,
    },
    backend: locizeOptions,
    saveMissing: true,
  });

export default i18n;
