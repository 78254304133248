export const FORM_STEPS = {
  CATEGORY: 1,
  BRAND: 2,
  DETAILS: 3,
  DONE: 4,
};

export const DEFAULT_FORM_VALUES = {
  priceUnit: localStorage.getItem("country") === "UZ" ? "UZS" : "TRY",
  productName: "",
  purchaseDate: "",
  productPrice: 0,
  unverifiedCategoryId: "",
  brand: "",
  customBrand: "",
  billFile: null,
};
